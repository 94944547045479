export const config = {
  apiUrl: process.env.REACT_APP_API_URL, //Change this value to api path when working in localhost
    firebase: {
      apiKey: "AIzaSyBELlFvBiDtHID6Lo7pBKzDQ6tWL4kHAfs",
      authDomain: "lbym-8be59.firebaseapp.com",
      projectId: "lbym-8be59",
      storageBucket: "lbym-8be59.appspot.com",
      messagingSenderId: "67087581587",
      appId: "1:67087581587:web:ea96ee610de15eff7e2866"
     }
}
