import blank from '../experiments/Blank.logo';
import FMA from '../experiments/FMA.logo';
import Absorption from '../experiments/Absorption.logo';
import ModelMolecules from '../experiments/ModelMolecules.logo';
import BasicBoard from '../experiments/BasicBoard.logo';
import Motion from '../experiments/Motion.logo';

export const experimentsList = [
    {
        name: "Blank",
        code: blank,
        view: 'main'
    },
    {
        name: "Basic Board",
        code: BasicBoard,
        view: 'main'
    },
    {
        name: "Motion",
        code: Motion,
        view: 'graph'
    },
    {
        name: "Absorption",
        code: Absorption,
        view: 'main'
    },
    {
        name: "Model Molecules",
        code: ModelMolecules,
        view: 'main'
    },
];